<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- FILTRO FINANCIACIONES -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left :small="pantallaChica">fas fa-filter</v-icon>
                <span :style="pantallaChica ? 'font-size:20px;' : 'font-size: 24px;'">Filtros</span>
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="get_financiaciones()">
                <v-row class="pb-0">
                  <v-col cols="12" sm="3" md="3" lg="3" xl="1" class="py-1">
                    Artículo
                    <v-text-field
                      v-model.trim="filtro.articulo"
                      type="number"
                      hide-details
                      outlined
                      dense
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" class="py-1">
                    Empresas
                    <v-autocomplete
                      v-model="filtro.empresa_codigo"
                      item-value="codigo"
                      item-text="nombre"
                      :items="confUsuario.empresas"
                      tabindex="1"
                      hide-details
                      outlined
                      dense
                      filled
                      readonly
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" class="py-1">
                    Canales
                    <v-autocomplete
                      v-model="filtro.canal_codigo"
                      item-value="canal_id"
                      item-text="canal_nombre"
                      :items="confUsuario.canales"
                      tabindex="1"
                      hide-details
                      outlined
                      dense
                      filled
                      readonly
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="2" md="3" lg="2"
                    class="" 
                    :class="pantallaChica ? 'py-3 pl-3' : 'py-6 pl-0'"
                    v-if="permisoDescarga == 1 && mostrarBotonDescarga"
                  >
                    <download-excel
                      :name="`financiaciones_y_promos.xls`"
                      :fields="financiones_header"
                      :fetch="getFinanciacionesPromociones"
                    >
                      <v-btn
                        class="mr-2 my-sm-0 my-1 py-sm-0 py-3"
                        title="Descargar Excel"
                        :x-small="pantallaChica"
                        :small="!pantallaChica"
                        :disabled="financiaciones.length == 0 || filtro.especiales == 0"
                      >
                        Financ vs Promo
                        <v-icon
                          color="green darken-3"
                          :small="pantallaChica"
                          right
                        >
                          fas fa-file-download
                        </v-icon>
                      </v-btn>
                    </download-excel>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" class="pt-0 mt-0 d-flex justfiy-end">
                    <BtnFiltro
                      :loading="load_fin"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- DATA TABLE FINANCIACIONES -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          sort-by="forma_pago_nombre"
          :headers="header_1/*confUsuario.permiso ? headers_fin : headers_vend*/"
          :items="financiaciones"
          :loading="load_fin"
          :search="search_fin"
          dense
        >
          <template v-slot:top>
            <v-row class="d-flex pa-2" no-gutters>
              <v-col cols="12" sm="6">
                <SearchDataTable
                  v-model="search_fin"
                  nombre="Buscar financiaciones"
                />
              </v-col>
              
              <v-col cols="12" sm="6" class="d-flex justify-end" v-if="permisoDescargaTabla == 1">
                <download-excel
                  :name="`financiaciones.xls`"
                  :fields="headers_excel"
                  :fetch="get_financiacionesExcel"
                >
                  <v-btn
                    class="mr-2 my-sm-0 my-1 py-sm-0 py-3"
                    title="Descargar Excel"
                    :x-small="pantallaChica"
                    :small="!pantallaChica"
                    :disabled="financiaciones.length == 0"
                  >
                    Excel
                    <v-icon
                      color="green darken-3"
                      :small="pantallaChica"
                      right
                    >
                      fas fa-file-download
                    </v-icon>
                  </v-btn>
                </download-excel>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.porcentaje`]="{ item }">
            <div style="min-width:max-content">{{ parseFloat(item.porcentaje.toFixed(2)) }} %</div>
          </template>
          <template v-slot:[`item.articulos`]="{ item }">
            <v-icon
              color="info"
              title="Detalle"
              :disabled="item.articulos != 1"
              small
              @click="open_financiacion('artículos', 4, item)"
            >
              {{ item.articulos == 1 ? 'fas fa-plus-circle' : 'fas fa-minus-circle' }}
            </v-icon>
          </template>
          <template v-slot:[`item.destinatarios`]="{ item }">
            <v-icon
              color="info"
              title="Detalle"
              small
              @click="open_financiacion('destinatarios', 5, item)"
            >
              fas fa-plus-circle
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-end pt-0 pb-0">
        <download-excel
          v-if="permisoDescarga == 1"
          :name="`promociones_y_financ.xls`"
          :fields="promociones_header"
          :fetch="getPromocionesConFinanciaciones"
          class=" pt-4"
        >
          <v-btn
            class="mr-2 my-sm-0 my-1 py-sm-0 py-3"
            title="Descargar Excel"
            :x-small="$vuetify.breakpoint.xs"
            :small="!$vuetify.breakpoint.xs"
            :disabled="promociones.length == 0"
          >
            Promo vs Finan
            <v-icon
              color="green darken-3"
              :small="$vuetify.breakpoint.xs"
              right
            >
              fas fa-file-download
            </v-icon>
          </v-btn>
        </download-excel>
        <!-- <v-switch 
          v-model="visible" 
          label="Vista en Tabla"
          class="mb-md-2"
          tabindex="1"
          :true-value="1"
          :false-value="0"
          hide-details
          dense>
        </v-switch> -->
      </v-col>
      <v-col cols="12" v-if="visible == 1" class="pt-0">
        <!-- DATA TABLE PROMOCIONES -->
        <v-data-table
          class="cebra elevation-2 mb-2"
          sort-by="promocion_nombre"
          :headers="headers_promo"
          :items="promociones"
          :loading="load_promo"
          :search="search_promo"
          dense
        >
          <template v-slot:top>
            <v-row class="d-flex pa-2" no-gutters>
              <v-col cols="12" sm="6">
                <SearchDataTable
                  v-model="search_promo"
                  nombre="Buscar promociones"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.porcentaje`]="{ item }">
            {{ parseFloat(item.porcentaje.toFixed(2)) }} %
          </template>
          <template v-slot:[`item.dias`]="{ item }">
            <v-icon
              color="info"
              title="Detalle"
              :disabled="item.dias != 1"
              small
              @click="open_promocion('días', 1, item)"
            >
              {{ item.dias == 1 ? 'fas fa-plus-circle' : 'fas fa-minus-circle' }}
            </v-icon>
          </template>
          <template v-slot:[`item.categorias`]="{ item }">
            <v-icon
              color="info"
              title="Detalle"
              :disabled="item.categorias != 1"
              small
              @click="open_promocion('categorías', 2, item)"
            >
              {{ item.categorias == 1 ? 'fas fa-plus-circle' : 'fas fa-minus-circle' }}
            </v-icon>
          </template>
          <template v-slot:[`item.articulos`]="{ item }">
            <v-icon
              color="info"
              title="Detalle"
              :disabled="item.articulos != 1"
              small
              @click="open_promocion('artículos', 3, item)"
            >
              {{ item.articulos == 1 ? 'fas fa-plus-circle' : 'fas fa-minus-circle' }}
            </v-icon>
          </template>
          <template v-slot:[`item.destinatarios`]="{ item }">
            <v-icon
              color="info"
              title="Detalle"
              small
              @click="open_promocion('destinatarios', 4, item)"
            >
              fas fa-plus-circle
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- COMPONENTES -->
    <ModalDetalleCondCom
      v-model="dialog_condcom"
      :datos="datos"
    />
    <ModalDetalleDestinatario
      v-model="dialog_destin"
      :datos="datos"
    />
    <!-- <ModalVerGruposFP 
      :datos="dialogGrupo"
      @setearVerGpoFP="setVerGpoFP"
    /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import SearchDataTable from '../../components/util/SearchDataTable'
import ModalDetalleCondCom from '../../components/consultas/ModalDetalleCondCom'
import ModalDetalleDestinatario from '../../components/consultas/ModalDetalleDestinatario'
import FlipCardVue from '../../components/generales/FlipCard.vue'
import FlipCardPromoVue from '../../components/generales/FlipCardPromo.vue'
import { roundNumber } from '../../util/utils'
import DownloadDataTableVue from '../../components/util/DownloadDataTable.vue'
import ModalVerGruposFP from '../../components/consultas/ModalVerGruposFP.vue'

export default {
  data () {
    return {
      panel: 0,
      load_promo: false,
      load_fin: false,
      dialog_condcom: false,
      dialog_destin: false,
      dialog_fin: false,
      permisoDescarga: 0,
      permisoDescargaTabla: 0,
      search_promo: '',
      search_fin: '',
      headers_promo: [
        { text: 'Nombre', value: 'promocion_nombre', sortable: false },
        { text: 'Desde', value: 'fecha_desde', sortable: false },
        { text: 'Hasta', value: 'fecha_hasta', sortable: false },
        { text: 'Procentaje', value: 'porcentaje', align: 'center', sortable: false },
        { text: 'Días', value: 'dias', align: 'center', sortable: false, filterable: false },
        { text: 'Categorías', value: 'categorias', align: 'center', sortable: false, filterable: false },
        { text: 'Artículos', value: 'articulos', align: 'center', sortable: false, filterable: false },
        //{ text: 'Destinatarios', value: 'destinatarios', align: 'center', sortable: false, filterable: false }
      ],
      headers_fin: [
        { text: 'Descripción', value: 'forma_pago_nombre', sortable: false },
        { text: 'Tarjeta', value: 'tarjeta_nombre', sortable: false },
        { text: 'Cuota', value: 'cuota_nombre', sortable: false },
        { text: '%', value: 'porcentaje', align: 'center', sortable: false },
        { text: 'Desde', value: 'vigencia_desde', sortable: false },
        { text: 'Hasta', value: 'vigencia_hasta', sortable: false },
        { text: 'Rubros', value: 'rubros', align: 'center', sortable: false, filterable: false },
        { text: 'Categorías', value: 'categorias', align: 'center', sortable: false, filterable: false },
        { text: 'Marca', value: 'marcas', align: 'center', sortable: false, filterable: false },
        { text: 'Artículos', value: 'articulos', align: 'center', sortable: false, filterable: false },
        { text: 'Dest', value: 'destinatarios', align: 'center', sortable: false, filterable: false },
        { text: 'Gpo', value: 'grupo_id', align: 'center', sortable: false, filterable: false }
      ],
      headers_vend: [
        { text: 'Descripción', value: 'forma_pago_nombre', sortable: false },
        { text: 'Tarjeta', value: 'tarjeta_nombre', sortable: false },
        { text: 'Cuota', value: 'cuota_nombre', sortable: false },
        { text: 'Desde', value: 'vigencia_desde', sortable: false },
        { text: 'Hasta', value: 'vigencia_hasta', sortable: false },
        { text: 'Rubros', value: 'rubros', align: 'center', sortable: false, filterable: false },
        { text: 'Categorías', value: 'categorias', align: 'center', sortable: false, filterable: false },
        { text: 'Marca', value: 'marcas', align: 'center', sortable: false, filterable: false },
        { text: 'Artículos', value: 'articulos', align: 'center', sortable: false, filterable: false }
      ],
      header_1: [
        { text: 'Descripción', value: 'forma_pago_nombre' },
        { text: 'Tarjeta', value: 'tarjeta_nombre', sortable: false },
        { text: 'Cuota', value: 'cuota_nombre', sortable: false },
        { text: 'Porc. (%)', value: 'porcentaje', align: 'end', sortable: false },
        { text: 'Desde', value: 'vigencia_desde' },
        { text: 'Hasta', value: 'vigencia_hasta' }
      ],
      promociones: [],
      promociones_excel: [],
      promociones_header: {
        'Artículos Código': 'articulo_codigo',
        'Artículo': 'articulo_nombre',
        'Categoría': 'categoria_nombre',
        'Rubro': 'rubro_nombre',
        'Promoción': 'promocion_nombre',
        'Fecha Desde': 'fecha_desde',
        'Fecha Hasta': 'fecha_hasta',
        'Porcentaje': 'porcentaje',
        'Canal': 'canal_nombre',
        'Sucursal': 'sucursal_nombre',
        'FP Especial': 'es_especial',
        'Financiaciones': 'financiacion'
      },
      financiaciones: [],
      financiaciones_excel:[],
      mostrarBotonDescarga: false,
      financiones_header: {
        'Artículo código': 'articulo_codigo',
        'Artículo': 'articulo_nombre',
        'Categoría': 'categoria_nombre',
        'Rubro': 'rubro_nombre',
        'Sin Interes': 'sin_interes',
        'Especial': 'especial',
        'Tarjeta': 'tarjeta_nombre',
        'Cuota': 'cuota_nombre',
        'Porcentaje': 'porcentaje',
        'Vigencia Hasta': 'vigencia_hasta',
        'Promociones': 'promociones',
        'Porcentaje Promo': 'porcentaje_promo',
        'Fecha Fin Promo': 'fecha_fin_promo',
        'Locales': 'locales',
        'Canales': 'canales',
        'Sucursales': 'sucursales',
      },
      headers_excel:{
        'Tarjeta Nombre': 'tarjeta_nombre',
        'Descripción': 'forma_pago_nombre',
        'Cuota Nombre': 'cuota_nombre',
        'Cuota Interna': 'cuota_interna',
        'Porcentaje': 'porcentaje',
        'Vigencia desde': 'vigencia_desde',
        'Vigencia hasta': 'vigencia_hasta',
        'Canales': 'canales'
      },

      datos: {
        nombre: '',
        opcion: null,
        id: null
      },
      filtro: {
        especiales: 0, //1,
        articulo: null,
        canal_codigo: 2,
        empresa_codigo: 2,
      },
      dialogGrupo: {
        activo: false,
        fp_cond_id: 0,
        nombre: ''
      },
      visible: 1, //0,
      visibleFinanciaciones: 0,
      pantallaChica: this.$vuetify.breakpoint.xs,
      //tieneRol: 0
    }
  },
  async created () {
    this.$store.state.loading = true
    // obtengo la configuracion del usuario
    await this.$store.dispatch('financiaciones/get_conf_user')
    await this.getPermiso();
    //await this.getTieneRol();
    this.$store.state.loading = false
    // obtengo las financiaciones y las promociones
    await this.get_financiaciones()
    await this.get_promociones()

  },
  computed: {
    ...mapState('financiaciones', ['confUsuario'])
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    ModalDetalleCondCom,
    ModalDetalleDestinatario,
    FlipCardVue,
    FlipCardPromoVue,
    DownloadDataTableVue,
    ModalVerGruposFP
  },
  methods: {
    async getPermiso(){
      const res = await this.$store.dispatch('financiaciones/getPermiso', { funcion_codigo: 157 });
      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', {text: res.message, color: 'error',})
      }
      this.permisoDescarga = res.permiso;

      const res1 = await this.$store.dispatch('financiaciones/getPermiso', { funcion_codigo: 159 });
      if(res1.resultado == 0){
        return this.$store.dispatch('show_snackbar', {text: res1.message, color: 'error',})
      }
      this.permisoDescargaTabla = res1.permiso;
    },
    /*async getTieneRol(){
      const res = await this.$store.dispatch('genericosAcc/getTieneRol', {rol: 'TELECOM_GESTOR' });
      console.log("res: ", res);
      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', {text: res.message, color: 'error',})
      }
      //this.tieneRol = res.data
    },*/
    open_promocion (tipo, opcion, item) {
      if (tipo == 'destinatarios') { 
        this.dialog_destin = true
      } else {
        this.dialog_condcom = true
      }
      this.datos = {
        nombre: tipo,
        opcion: opcion,
        tipo: 'PROMO',
        id: item.promocion_id
      }
    },
    open_financiacion (tipo, opcion, item) {
      if (tipo == 'destinatarios') { 
        this.dialog_destin = true
      } else if (tipo == 'grupo'){
        this.dialogGrupo.fp_cond_id = item.forma_pago_id
        this.dialogGrupo.nombre = item.forma_pago_nombre
        this.dialogGrupo.activo = true
        return
      } else {
        this.dialog_condcom = true
      }
      this.datos = {
        nombre: tipo,
        opcion: opcion,
        tipo: 'FIN',
        id: item.forma_pago_id
      }
    },
    //Boton buscar
    async get_financiaciones () {
      this.load_fin = true
      this.search_fin = ''
      this.financiaciones = [];
      await this.$store.dispatch('financiaciones/get_financiaciones_condcom', {
        articulo: this.filtro.articulo ? this.filtro.articulo : 0,
        especial: this.filtro.especiales,
        locales: this.confUsuario.locales,
        sucursales: this.confUsuario.sucursales,
        empresas: this.filtro.empresa_codigo ? this.confUsuario.empresas.filter(e => e.codigo == this.filtro.empresa_codigo) : this.confUsuario.empresas,
        canales: this.filtro.canal_codigo ? this.confUsuario.canales.filter(e => e.canal_id == this.filtro.canal_codigo) : this.confUsuario.canales
      })
        .then((res) => {
          this.financiaciones = res.formas_pago_habilitadas;
          this.mostrarBotonDescarga = this.financiaciones.length != 0 && this.filtro.especiales == 1 ? true : false;
          // 31-08-2023 por MMURILLO, completo los datos de rubro y destinatarios
          //this.completar_financiaciones(res.datos_destinatarios, res.datos_rubros, res.datos_categorias, res.datos_marcas, res.datos_articulos)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load_fin = false
    },
    async get_financiacionesExcel(){
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('financiaciones/get_financiacionesExcel',{
        articulo: this.filtro.articulo ? this.filtro.articulo : 0,
        especial: this.filtro.especiales,
        locales: this.confUsuario.locales,
        sucursales: this.confUsuario.sucursales,
        empresas: this.filtro.empresa_codigo ? this.confUsuario.empresas.filter(e => e.codigo == this.filtro.empresa_codigo) : this.confUsuario.empresas,
        canales: this.filtro.canal_codigo ? this.confUsuario.canales.filter(e => e.canal_id == this.filtro.canal_codigo) : this.confUsuario.canales
      });
      this.$store.state.loading = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', {text: res.message, color: 'error',})
      }
      return res.financiaciones;
    },
    //Method para descargar un excel de financiaciones y sus respectivas promociones si las tiene
    async getFinanciacionesPromociones(){
      this.$store.state.loading = true
      const res = await this.$store.dispatch('financiaciones/getFinanciacionesPorPromociones', {
        articulo: this.filtro.articulo ? this.filtro.articulo : 0,
        especial: this.filtro.especiales,
        locales: this.confUsuario.locales,
        sucursales: this.confUsuario.sucursales,
        empresas: this.filtro.empresa_codigo ? this.confUsuario.empresas.filter(e => e.codigo == this.filtro.empresa_codigo) : this.confUsuario.empresas,
        canales: this.filtro.canal_codigo ? this.confUsuario.canales.filter(e => e.canal_id == this.filtro.canal_codigo) : this.confUsuario.canales
      })
      this.$store.state.loading = false
      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', {text: res.message, color: 'error',})
      }
      this.financiaciones_excel = res.financiaciones_con_promociones;
      this.financiaciones_excel.forEach(e => {
        if(e.porcentaje == 0){
          e.especial = 'Si';
        }else e.especial = '';
      })
      return this.financiaciones_excel;
    },
    async get_promociones () {
      this.load_promo = true
      this.search_promo = ''
      this.promociones = []
      await this.$store.dispatch('financiaciones/get_promociones', {
        sucursales: this.confUsuario.sucursales,
        empresas:   this.confUsuario.empresas.filter(e => e.codigo == 2),
        canales:    this.confUsuario.canales.filter(e => e.canal_id == 2)
      })
        .then((res) => {
          this.promociones = res.promoUsuario
          this.completar_promociones(res.datos_dias, res.datos_categorias, res.datos_articulos, res.datos_destinatarios, res.datos_arts_exceptuados)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load_promo = false
    },
    //trae las promociones y si tiene o no financiaciones (opc=1)
    async getPromocionesConFinanciaciones(){
      this.$store.state.loading = true
        const res = await this.$store.dispatch('financiaciones/getPromocionesConFinanciaciones', {
          empresas: this.confUsuario.empresas,
          locales: this.confUsuario.locales,
          sucursales: this.confUsuario.sucursales,
          canales: this.confUsuario.canales
        })
        this.$store.state.loading = false
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', {text: res.message, color: 'error',})
        }
        this.promociones_excel = res.promociones_con_financiaciones;
        return this.promociones_excel;
    },
    limpiar () {
      /*this.filtro = {
        especiales: 1,
        articulo: null,
        canal_codigo: null,
        empresa_codigo: null,
      }*/
     this.filtro.articulo = null;
    },
    completar_financiaciones(destinatarios, rubros, categ, marcas, arts){
      if (this.financiaciones.length > 0){
        for (let id in this.financiaciones){
          // setear alto y ancho de la tarjeta
          this.financiaciones[id].height = '350px'
          this.financiaciones[id].width = this.$vuetify.breakpoint.mdAndUp ? '350px' : '300px'
          this.financiaciones[id].permiso = this.confUsuario.permiso == true ? 1 : 0
          // armo la cadena de rubros
          let cadenaRubros = 'Aplica a Todos los Rubros.'
          let existeRubro = rubros.filter(element => element.forma_pago_cond_id == this.financiaciones[id].forma_pago_id)
          if (existeRubro.length > 0){
            cadenaRubros = `Aplica a los Rubros `
            for (let jd in existeRubro){
              cadenaRubros = cadenaRubros + `${existeRubro[jd].rubro_nombre}`
              if (parseInt(jd) < existeRubro.length - 1) cadenaRubros = cadenaRubros + `, `
              else cadenaRubros = cadenaRubros + `.`
            }
          }
          this.financiaciones[id].cadena_rubros = cadenaRubros
          // armo la cadena de categorias
          let cadenaCategorias = 'Aplica a Todas las Categorias.'
          let existeCat = categ.filter(element => element.forma_pago_cond_id == this.financiaciones[id].forma_pago_id)
          if (existeCat.length > 0){
            cadenaCategorias = `Aplica a las Categorias `
            for (let jd in existeCat){
              cadenaCategorias = cadenaCategorias + `${existeCat[jd].categoria_nombre}`
              if (parseInt(jd) < existeCat.length - 1) cadenaCategorias = cadenaCategorias + `, `
              else cadenaCategorias = cadenaCategorias + `.`
            }
          }
          this.financiaciones[id].cadena_categorias = cadenaCategorias
          // armo la cadena de marcas
          let cadenaMarcas = `Aplica a Todas las Marcas.`
          let existeMarca = marcas.filter(element => element.forma_pago_cond_id == this.financiaciones[id].forma_pago_id)
          if (existeMarca.length > 0){
            cadenaMarcas = `Aplica a las Marcas `
            for (let jd in existeMarca){
              cadenaMarcas = cadenaMarcas + `${existeMarca[jd].marca_nombre}`
              if (parseInt(jd) < existeMarca.length - 1) cadenaMarcas = cadenaMarcas + `, `
              else cadenaMarcas = cadenaMarcas + `.`
            }
          }
          this.financiaciones[id].cadena_marcas = cadenaMarcas
          // armo la cadena de articulos
          let cadenaArticulos = `Aplica a Todos los Artículos.`
          let existeArt = arts.filter(element => element.forma_pago_cond_id == this.financiaciones[id].forma_pago_id)
          if (existeArt.length > 0){
            cadenaArticulos = `Aplica a los Artículos `
            for (let jd in existeArt){
              cadenaArticulos = cadenaArticulos + `${existeArt[jd].articulo_codigo} - ${existeArt[jd].articulo_nombre}`
              if (parseInt(jd) < existeArt.length - 1) cadenaArticulos = cadenaArticulos + `, `
              else cadenaArticulos = cadenaArticulos + `.`
            }
          }
          this.financiaciones[id].cadena_articulos = cadenaArticulos
          // armo la cadena de Destinatarios
          let cadenaDestinatario = 'Aplica a todos los Canales, Sucursales y Locales.'
          let existeDest = destinatarios.filter(element => element.forma_pago_cond_id == this.financiaciones[id].forma_pago_id)
          if (existeDest.length > 0){
            // tengo canales, locales y sucursales listados, comienzo con los canales
            cadenaDestinatario = `Aplica a los siguientes CANALES: `
            if (existeDest[0].canales.length == 0) cadenaDestinatario = cadenaDestinatario + `TODOS, SUCURSALES: `
            else{
              for (let jd in existeDest[0].canales){
                cadenaDestinatario = cadenaDestinatario + `${existeDest[0].canales[jd].canal_nombre.toString().trim().toUpperCase()}, `
              }
              cadenaDestinatario = cadenaDestinatario + `SUCURSALES: `
            }
            // sucursales
            if (existeDest[0].sucursales.length == 0) cadenaDestinatario = cadenaDestinatario + `TODAS, LOCALES: `
            else{
              for (let jd in existeDest[0].sucursales){
                cadenaDestinatario = cadenaDestinatario + `${existeDest[0].sucursales[jd].sucursal_nombre.toString().trim().toUpperCase()}, `
              }
              cadenaDestinatario = cadenaDestinatario + `LOCALES: `
            }
            // locales
            if (existeDest[0].locales.length == 0) cadenaDestinatario = cadenaDestinatario + `TODOS.`
            else {
              for (let jd in existeDest[0].locales){
                cadenaDestinatario = cadenaDestinatario + `${existeDest[0].locales[jd].pto_vta_nombre.toString().trim().toUpperCase()}`
                if (parseInt(jd) < existeDest[0].locales.length - 1) cadenaDestinatario = cadenaDestinatario + `, `
                else cadenaDestinatario = cadenaDestinatario + `.`
              }
            }
          }
          this.financiaciones[id].cadena_destinatarios = cadenaDestinatario //+ 'asasddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd'
        }
      }
    },
    completar_promociones(dias, categ, arts, dest, excep){
      if (this.promociones.length > 0){
        for (let id in this.promociones){
          // setear alto y ancho de la tarjeta
          this.promociones[id].height = '350px'
          this.promociones[id].width = this.$vuetify.breakpoint.mdAndUp ? '350px' : '300px'
          this.promociones[id].permiso = this.confUsuario.permiso == true ? 1 : 0
          this.promociones[id].porcentaje = roundNumber(this.promociones[id].porcentaje, 2)
          // armo la cadena de dias
          let cadenaDias = 'Aplica a Todos los días.'
          let existeDia = dias.filter(element => element.promocion_id == this.promociones[id].promocion_id)
          if (existeDia.length > 0){
            cadenaDias = `Aplica a los días `
            for (let jd in existeDia){
              cadenaDias = cadenaDias + `${existeDia[jd].dia_nombre}`
              if (parseInt(jd) < existeDia.length - 1) cadenaDias = cadenaDias + `, `
              else cadenaDias = cadenaDias + `.`
            }
          }
          this.promociones[id].cadena_dias = cadenaDias
          // armo la cadena de categorias
          this.promociones[id].cadena_categorias = ''
          let existeCat = categ.filter(element => element.promocion_id == this.promociones[id].promocion_id)
          if (existeCat.length > 0){
            for (let jd in existeCat){
              this.promociones[id].cadena_categorias = this.promociones[id].cadena_categorias + `${existeCat[jd].categoria_nombre}`
              if (parseInt(jd) < existeCat.length - 1) this.promociones[id].cadena_categorias = this.promociones[id].cadena_categorias + `, `
              else this.promociones[id].cadena_categorias = this.promociones[id].cadena_categorias + `.`
            }
          }
          // armo la cadena de articulos
          // let cadenaArticulos = `Aplica a Todos los Artículos.`
          let existeArt = arts.filter(element => element.promocion_id == this.promociones[id].promocion_id)
          if (existeArt.length > 0){
            this.promociones[id].datos_articulos = existeArt
            // cadenaArticulos = `Aplica a los Artículos `
            // for (let jd in existeArt){
            //   cadenaArticulos = cadenaArticulos + `${existeArt[jd].articulo_codigo} - ${existeArt[jd].articulo_nombre}`
            //   if (parseInt(jd) < existeArt.length - 1) cadenaArticulos = cadenaArticulos + `, `
            //   else cadenaArticulos = cadenaArticulos + `.`
            // }
          }
          // this.promociones[id].cadena_articulos = cadenaArticulos
          // articulos exceptuados
          let existeArtEx = excep.filter(element => element.promocion_id == this.promociones[id].promocion_id)
          if (existeArtEx.length > 0){
            this.promociones[id].datos_arts_exceptuados = existeArtEx
          }
          // armo la cadena de Destinatarios
          this.promociones[id].cadena_canales = ''
          this.promociones[id].cadena_sucursales = ''
          this.promociones[id].cadena_locales = ''
          let existeDest = dest.filter(element => element.promocion_id == this.promociones[id].promocion_id)
          if (existeDest.length > 0){
            // tengo canales, locales y sucursales listados, comienzo con los canales
            if (existeDest[0].canales.length > 0){
              for (let jd in existeDest[0].canales){
                this.promociones[id].cadena_canales = this.promociones[id].cadena_canales + `${existeDest[0].canales[jd].canal_nombre.toString().trim().toUpperCase()}`
                if (parseInt(jd) < existeDest[0].canales.length - 1) this.promociones[id].cadena_canales = this.promociones[id].cadena_canales + `, `
                else this.promociones[id].cadena_canales = this.promociones[id].cadena_canales + `.`
              }
            }
            // sucursales
            if (existeDest[0].sucursales.length > 0){
              for (let jd in existeDest[0].sucursales){
                this.promociones[id].cadena_sucursales = this.promociones[id].cadena_sucursales + `${existeDest[0].sucursales[jd].sucursal_nombre.toString().trim().toUpperCase()}`
                if (parseInt(jd) < existeDest[0].sucursales.length - 1) this.promociones[id].cadena_sucursales = this.promociones[id].cadena_sucursales + `, `
                else this.promociones[id].cadena_sucursales = this.promociones[id].cadena_sucursales + `.`
              }
            }
            // locales
            if (existeDest[0].locales.length > 0){
              for (let jd in existeDest[0].locales){
                this.promociones[id].cadena_locales = this.promociones[id].cadena_locales + `${existeDest[0].locales[jd].local_nombre.toString().trim().toUpperCase()}`
                if (parseInt(jd) < existeDest[0].locales.length - 1) this.promociones[id].cadena_locales = this.promociones[id].cadena_locales + `, `
                else this.promociones[id].cadena_locales = this.promociones[id].cadena_locales + `.`
              }
            }
          }
        }
      }
    },
    setVerGpoFP(value){
      this.dialogGrupo.activo = value
      if (this.dialogGrupo.activo == false){
        this.dialogGrupo.fp_cond_id = 0
        this.dialogGrupo.nombre = ''
      }
    }
  },
  watch:{
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  }
}
</script>